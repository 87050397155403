<template>
  <div>
    <v-card id="testContent">
      <v-card-text class="pa-2">
        <ContactUploadTable
          test
        />
        <v-row>
          <v-col
            cols="12"
          >
            <div
              v-if="splitMixin_expanded.length > 0 && scrollSplitIndex !== -1"
              class="fab-container"
              :style="{ right: (chatDrawer ? 420 : 0) + 'px' }"
            >
              <v-speed-dial
                v-model="splitActionsFab"
                top
                right
                direction="bottom"
                transition="slide-y-transition"
              >
                <template v-slot:activator>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-model="splitActionsFab"
                        :color="$_splitMixin_splitColors[scrollSplitIndex]"
                        large
                        dark
                        fab
                        v-on="on"
                      >
                        <v-icon v-if="splitActionsFab">
                          mdi-close
                        </v-icon>
                        <span
                          v-else
                          class="text-h5"
                        >
                          {{ $_splitMixin_indexToChar(scrollSplitIndex) }}
                        </span>
                      </v-btn>
                    </template>
                    <span>Split {{ $_splitMixin_indexToChar(scrollSplitIndex) }} Actions</span>
                  </v-tooltip>
                </template>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :disabled="isLoading()"
                      fab
                      small
                      color="success"
                      v-on="on"
                      @click="sendSplitTest(scrollSplitIndex)"
                    >
                      <v-icon>mdi-send</v-icon>
                    </v-btn>
                  </template>
                  <span>Test Split {{ $_splitMixin_indexToChar(scrollSplitIndex) }}</span>
                </v-tooltip>
              </v-speed-dial>
            </div>
            <v-expansion-panels
              v-model="splitMixin_expanded"
              multiple
            >
              <v-expansion-panel
                v-for="(item, i) in splitMixin_splits"
                :key="`test-panel-${i}`"
              >
                <v-expansion-panel-header>
                  <v-row>
                    <v-col
                      v-if="$_splitMixin_isSplit"
                      cols="4"
                      sm="3"
                      md="2"
                    >
                      <v-progress-linear
                        striped
                        height="20"
                        :value="item.distribution"
                        :color="$_splitMixin_splitColors[i]"
                      >
                        <template v-slot="{ value }">
                          <strong>{{ value }}%</strong>
                        </template>
                      </v-progress-linear>
                    </v-col>
                    <v-col
                      cols="8"
                      sm="9"
                      md="10"
                    >
                      <div
                        v-if="$_splitMixin_isSplit"
                        class="inline-block"
                      >
                        Split {{ $_splitMixin_indexToChar(i) }}
                      </div>
                      <div
                        v-else
                        class="inline-block"
                      >
                        {{ item.subject }}
                      </div>
                      <!-- <div
                        class="inline-block"
                      >
                        <v-tooltip
                          v-if="item.textAnalysis && item.textAnalysis.subjectSummary"
                          top
                        >
                          <template v-slot:activator="{ on }">
                            <span
                              v-if="!item.textAnalysis.loading"
                              class="text-subtitle-1"
                            >
                              <v-icon
                                :color="item.textAnalysis.subjectSummary.color"
                                size="20"
                                class="inline-block pl-6"
                                v-on="on"
                              >
                                {{ item.textAnalysis.subjectSummary.icon }}
                              </v-icon>
                              {{ item.textAnalysis.subjectSummary.value }}%
                            </span>
                          </template>
                          <span v-if="!item.textAnalysis.loading">{{ item.textAnalysis.subjectSummary.label }} Subject</span>
                          <span v-else>Loading</span>
                        </v-tooltip>
                        <v-tooltip
                          v-if="item.textAnalysis && item.textAnalysis.htmlSummary"
                          top
                        >
                          <template v-slot:activator="{ on }">
                            <span
                              v-if="!item.textAnalysis.loading"
                              class="text-subtitle-1"
                            >
                              <v-icon
                                :color="item.textAnalysis.htmlSummary.color"
                                size="20"
                                class="inline-block pl-2"
                                v-on="on"
                              >
                                {{ item.textAnalysis.htmlSummary.icon }}
                              </v-icon>
                              {{ item.textAnalysis.htmlSummary.value }}%
                            </span>
                          </template>
                          <span v-if="!item.textAnalysis.loading">{{ item.textAnalysis.htmlSummary.label }} Creative</span>
                          <span v-else>Loading</span>
                        </v-tooltip>
                        <v-skeleton-loader
                          v-if="item.textAnalysis && item.textAnalysis.loading"
                          class="pa-0"
                          type="heading"
                        />
                      </div> -->
                      <v-tooltip
                        v-if="item.spam"
                        top
                      >
                        <template v-slot:activator="{ on }">
                          <div
                            class="inline-block"
                            v-on="on"
                          >
                            <v-rating
                              v-if="!item.spam.loading"
                              v-model="item.spam.scoreRounded"
                              :length="item.spam.maxScore"
                              empty-icon="mdi-shield-outline"
                              full-icon="mdi-shield-alert"
                              half-icon="mdi-shield-half-full"
                              half-increments
                              readonly
                              dense
                              :color="spamRatingColor(item)"
                              :background-color="spamRatingBackgroundColor(item)"
                              size="20"
                              class="inline-block pl-6"
                            />
                            <v-skeleton-loader
                              v-else
                              type="heading"
                            />
                          </div>
                        </template>
                        <span v-if="!item.spam.loading">Spam Score: {{ item.spam.score }}/{{ item.spam.maxScore }}</span>
                        <span v-else>Loading</span>
                      </v-tooltip>
                      <div
                        class="inline-block"
                      >
                        <v-skeleton-loader
                          v-if="!item.links || item.links.loading"
                          type="heading"
                        />
                        <div
                          v-else-if="item.links.items.length"
                          class="pl-6"
                        >
                          <span v-if="!item.links.loading && item.links.totalInvalid > 0">Links: {{ item.links.totalInvalid }}/{{ item.links.items.length }} Invalid</span>
                          <span v-else-if="!item.links.loading && item.links.totalInvalid === 0">Links: All {{ item.links.items.length }} Valid</span>
                        </div>
                      </div>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            class="pl-6"
                            :color="splitsAreTested[i] ? 'success' : 'grey'"
                            v-on="on"
                          >
                            {{ splitsAreTested[i] ? 'mdi-send-check' : 'mdi-send' }}
                          </v-icon>
                        </template>
                        <span v-if="splitsAreTested[i]">Tested on {{ $_global_parseAsUtc(splitsTestedOn[i]) | displayDateTimeFormat }}</span>
                        <span v-else>Not tested</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <template v-slot:actions>
                    <v-icon
                      :color="$_splitMixin_splitColors[i]"
                    >
                      $expand
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  v-intersect.quiet="{
                    handler: onIntersect,
                    options: {
                      threshold: [0.0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1.0]
                    }
                  }"
                  :class="$_splitMixin_indexToChar(i)"
                >
                  <v-divider />
                  <v-row class="ma-0">
                    <v-col
                      :style="`height: ${containerHeight[i]}px; overflow: auto;`"
                      cols="12"
                      md="6"
                    >
                      <SplitPreview
                        :id="item.id"
                        ref="splitPreview"
                        @change="value => changedDevice(value, item, i)"
                      />
                    </v-col>
                    <v-col
                      :style="`height: ${containerHeight[i]}px; overflow: auto;`"
                      cols="12"
                      md="6"
                    >
                      <v-expansion-panels
                        multiple
                        :value="[0,1]"
                      >
                        <!-- <v-expansion-panel>
                          <v-expansion-panel-header class="text-h6">
                            Text Analysis
                            <div
                              class="inline-block"
                              v-if="item.textAnalysis && item.textAnalysis.subjectSummary && item.textAnalysis.htmlSummary"
                            >
                              <v-tooltip
                                top
                              >
                                <template v-slot:activator="{ on }">
                                  <span v-if="!item.textAnalysis.loading" class="text-subtitle-1">
                                    <v-icon
                                      :color="item.textAnalysis.subjectSummary.color"
                                      size="20"
                                      class="inline-block pl-6"
                                      v-on="on"
                                    >
                                      {{ item.textAnalysis.subjectSummary.icon }}
                                    </v-icon>
                                    {{ item.textAnalysis.subjectSummary.value }}%
                                  </span>
                                </template>
                                <span v-if="!item.textAnalysis.loading">{{ item.textAnalysis.subjectSummary.label }} Subject</span>
                                <span v-else>Loading</span>
                              </v-tooltip>
                              <v-tooltip
                                top
                              >
                                <template v-slot:activator="{ on }">
                                  <span v-if="!item.textAnalysis.loading" class="text-subtitle-1">
                                    <v-icon
                                      :color="item.textAnalysis.htmlSummary.color"
                                      size="20"
                                      class="inline-block pl-2"
                                      v-on="on"
                                    >
                                      {{ item.textAnalysis.htmlSummary.icon }}
                                    </v-icon>
                                    {{ item.textAnalysis.htmlSummary.value }}%
                                  </span>
                                </template>
                                <span v-if="!item.textAnalysis.loading">{{ item.textAnalysis.htmlSummary.label }} Creative</span>
                                <span v-else>Loading</span>
                              </v-tooltip>
                            </div>
                            <v-skeleton-loader
                              v-else-if="item.textAnalysis.loading"
                              class="pl-6"
                              type="heading"
                            />
                            <template v-slot:actions>
                              <v-icon
                                :color="$_splitMixin_splitColors[i]"
                              >
                                $expand
                              </v-icon>
                            </template>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <TextAnalysis
                              :analysis="item.textAnalysis"
                              @get-text-analysis="$_splitMixin_getTextAnalysis(i)"
                            />
                          </v-expansion-panel-content>
                        </v-expansion-panel> -->
                        <v-expansion-panel>
                          <v-expansion-panel-header class="text-h6">
                            Spam Score
                            <v-tooltip
                              v-if="item.spam"
                              top
                            >
                              <template v-slot:activator="{ on }">
                                <div
                                  class="inline-block"
                                  v-on="on"
                                >
                                  <v-rating
                                    v-if="!item.spam.loading"
                                    v-model="item.spam.scoreRounded"
                                    :length="item.spam.maxScore"
                                    empty-icon="mdi-shield-outline"
                                    full-icon="mdi-shield-alert"
                                    half-icon="mdi-shield-half-full"
                                    half-increments
                                    readonly
                                    dense
                                    :color="spamRatingColor(item)"
                                    :background-color="spamRatingBackgroundColor(item)"
                                    size="20"
                                    class="inline-block pl-6"
                                  />
                                  <v-skeleton-loader
                                    v-else
                                    type="heading"
                                  />
                                </div>
                              </template>
                              <span v-if="!item.spam.loading">Spam Score: {{ item.spam.score }}/{{ item.spam.maxScore }}</span>
                              <span v-else>Loading</span>
                            </v-tooltip>
                            <template v-slot:actions>
                              <v-icon
                                :color="$_splitMixin_splitColors[i]"
                              >
                                $expand
                              </v-icon>
                            </template>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <SpamScore
                              :spam="item.spam"
                            />
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                          <v-expansion-panel-header class="text-h6">
                            Link Validation
                            <div
                              class="inline-block"
                            >
                              <v-skeleton-loader
                                v-if="!item.links || item.links.loading"
                                type="heading"
                              />
                              <div
                                v-else-if="item.links.items.length"
                                class="pl-6 text-subtitle-1"
                              >
                                <span v-if="!item.links.loading && item.links.totalInvalid > 0">{{ item.links.totalInvalid }}/{{ item.links.items.length }} Invalid</span>
                                <span v-else-if="!item.links.loading && item.links.totalInvalid === 0">Links: All {{ item.links.items.length }} Valid</span>
                              </div>
                            </div>
                            <template v-slot:actions>
                              <v-icon
                                :color="$_splitMixin_splitColors[i]"
                              >
                                $expand
                              </v-icon>
                            </template>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <LinkChecker
                              :split-id="item.id"
                              :links="item.links"
                              @set-link-validation="value => $_splitMixin_setLinks(i, value)"
                              @load-split-html="loadSplit(i)"
                            />
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="success"
          :loading="isLoading()"
          @click="testAll()"
        >
          Test All
          <v-icon right>
            mdi-send-circle
          </v-icon>
        </v-btn>
        <v-btn
          color="tertiary"
          :disabled="isLoading()"
          @click="cancel()"
        >
          <v-icon left>
            mdi-menu-left-outline
          </v-icon>Back
        </v-btn>
        <v-btn
          color="success"
          :loading="isLoading()"
          @click="submitContent()"
        >
          Next
          <v-icon right>
            mdi-menu-right-outline
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Vue from 'vue'
import { splitMixin, splitMixinMailing } from '@/shared/mixins/mailing'
import { mapGetters, mapActions, mapState } from 'vuex'
import { mailingService } from '@/shared/services'

export default {
  name: 'TestContent',

  components: {
    LinkChecker: () => import('@/views/pages/components/mailing/LinkChecker'),
    SpamScore: () => import('@/views/pages/components/mailing/SpamScore'),
    SplitPreview: () => import('@/views/pages/components/mailing/SplitPreview'),
    // TextAnalysis: () => import('@/views/pages/components/mailing/TextAnalysis'),
    ContactUploadTable: () => import('@/views/pages/components/mailing/ContactUploadTable')
  },

  mixins: [splitMixin, splitMixinMailing],

  props: {
    stepVal: {
      type: Number,
      default: 3
    }
  },

  data () {
    return {
      editorData: ``,
      splitActionsFab: false,
      scrollSplitIndex: -1,
      scrollMaxThreshold: 0,
      unwatchPersonalizations: [],
      splitsAreTested: [false, false, false, false],
      splitsTestedOn: [null, null, null, null],
      containerHeight: [800, 800, 800, 800]
    }
  },

  computed: {
    ...mapGetters(['isLoading', 'simulatedCustomerCrmId']),
    ...mapGetters('mailing', ['getPersonalizations', 'getMailingItemId', 'getMailingTenantCrmId', 'getContacts', 'getTargetId', 'getSplitIsTested', 'getSplit']),
    ...mapState(['chatDrawer']),
    contacts () {
      return this.getContacts()
    }
  },

  created () {
    this.init()
  },

  methods: {
    ...mapActions('mailing', ['testSplit', 'testMailing']),
    async init () {
      this.splitMixin_splits.forEach((item, i) => {
        this.splitsTestedOn[i] = item.testedOn
      })
      await this.getAllSplitsAreTested()
      this.loadSplits()
      await this.$_splitMixin_loadSpamScores()
      await this.$_splitMixin_validateLinks()
    },
    destroy () {
      this.removePersonalizationWatchers()
    },
    removePersonalizationWatchers () {
      this.unwatchPersonalizations.forEach(unwatch => {
        unwatch()
      })
    },
    cancel () {
      this.$emit('cancelStep', this.stepVal)
    },
    submitContent () {
      // check to make sure that all splits are tested
      let invalidIndices = []
      this.splitsAreTested.forEach((isTested, i) => {
        if (!isTested && i < this.splitMixin_splits.length) {
          invalidIndices.push(i)
        }
      })
      if (invalidIndices.length > 0) {
        this.splitMixin_expanded = invalidIndices
        this.$store.commit('setGlobalSnackbar', {
          message: `Mailing Split(s) need to be tested`,
          color: 'error'
        })
        return
      }
      const hasStatusCode410 = this.splitMixin_splits.some((split) =>
        split.links.items.some((item) => item.statusCode === 410))

      if (hasStatusCode410) {
        this.$store.commit('setGlobalSnackbar', {
          message: `Fix Links in Red`,
          color: 'error'
        })
        return
      }
      this.$emit('advanceFromStep', this.stepVal)
    },
    async getAllSplitsAreTested () {
      this.splitMixin_splits.forEach(async (split, i) => {
        var isTested = await mailingService.getSplitIsTested(split.id)
        Vue.set(this.splitsAreTested, i, isTested)
      })
    },
    loadSplits () {
      this.splitMixin_splits.forEach((item, i) => {
        if (this.$refs.splitPreview && this.$refs.splitPreview[i]) {
          this.$refs.splitPreview[i].loadSplit()
        }
      })
    },
    loadSplit (i) {
      this.$refs.splitPreview[i].loadSplit()
    },
    validateContactsSelected () {
      if (!this.contacts || this.contacts.length === 0) {
        this.$store.commit('setGlobalSnackbar', {
          message: `You must choose contacts in order to test`,
          color: 'error'
        })
        this.$vuetify.goTo(0)
        return false
      }
      return true
    },
    async sendSplitTest (index) {
      if (!this.validateContactsSelected()) return

      if (this.splitMixin_splits[index].spam.loading || !this.splitMixin_splits[index].spam.isValid) {
        this.$store.commit('setGlobalSnackbar', {
          message: `Spam Score(s) exceeded threshold`,
          color: 'error'
        })
        return
      }

      this.splitsTestedOn[index] = await this.testSplit(index)
      await this.getAllSplitsAreTested()
    },
    async testAll () {
      if (!this.validateContactsSelected()) return

      let invalidIndices = []
      this.splitMixin_splits.forEach((split, i) => {
        if (split.spam.loading || !split.spam.isValid) {
          invalidIndices.push(i)
        }
      })
      if (invalidIndices.length > 0) {
        this.splitMixin_expanded = invalidIndices
        this.$store.commit('setGlobalSnackbar', {
          message: `Spam Score(s) exceeded threshold`,
          color: 'error'
        })
        return
      }
      const hasStatusCode410 = this.splitMixin_splits.some((split) =>
        split.links.items.some((item) => item.statusCode === 410))

      if (hasStatusCode410) {
        this.$store.commit('setGlobalSnackbar', {
          message: `Fix Links in Red`,
          color: 'error'
        })
        return
      }
      var date = await this.testMailing(this.getMailingItemId())
      this.splitsTestedOn = this.splitsTestedOn.map(x => date)
      // this.splitsTestedOn.forEach((item, i) => {
      //   item = date
      // })
      await this.getAllSplitsAreTested()
    },
    spamRatingColor (item) {
      if (!item.spam) return 'grey'
      if (!item.spam.isValid) return 'error'
      return 'warning'
    },
    spamRatingBackgroundColor (item) {
      if (!item.spam || item.spam.score >= 0.25) return 'grey'
      return 'success'
    },
    linkRatingBackgroundColor (item) {
      if (!item.links || item.links.totalInvalid > 0) return 'grey'
      return 'success'
    },
    onIntersect (entries, observer) {
      if (entries[0].intersectionRatio > this.scrollMaxThreshold) {
        this.scrollMaxThreshold = entries[0].intersectionRatio
        this.scrollSplitIndex = this.$_splitMixin_splitLetters.indexOf(entries[0].target.classList[1])
      } else if (this.scrollSplitIndex === this.$_splitMixin_splitLetters.indexOf(entries[0].target.classList[1])) {
        this.scrollMaxThreshold = entries[0].intersectionRatio
        // if (this.scrollMaxThreshold <= 0.1) {
        //   this.scrollSplitIndex = -1
        // }
      }
    },
    setLinks (i, links) {
      this.splitMixin_splits[i].links = links
    },
    changedDevice (device, split, i) {
      var additionalHeight = 35
      if (device.value === 0) {
        additionalHeight = 7
      }
      this.containerHeight[i] = device.height + additionalHeight + 144
    }
  }
}
</script>

<style lang="scss" scoped>
.fab-container {
  z-index: 6;
  position: fixed;
  top: 50%;
  transition: 0.25s;
}
.fab-container .v-size--large {
  height: 50px;
  width: 50px;
}
</style>
