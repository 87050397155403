var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { id: "testContent" } },
        [
          _c(
            "v-card-text",
            { staticClass: "pa-2" },
            [
              _c("ContactUploadTable", { attrs: { test: "" } }),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.splitMixin_expanded.length > 0 &&
                      _vm.scrollSplitIndex !== -1
                        ? _c(
                            "div",
                            {
                              staticClass: "fab-container",
                              style: {
                                right: (_vm.chatDrawer ? 420 : 0) + "px"
                              }
                            },
                            [
                              _c(
                                "v-speed-dial",
                                {
                                  attrs: {
                                    top: "",
                                    right: "",
                                    direction: "bottom",
                                    transition: "slide-y-transition"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function() {
                                          return [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    _vm
                                                                      .$_splitMixin_splitColors[
                                                                      _vm
                                                                        .scrollSplitIndex
                                                                    ],
                                                                  large: "",
                                                                  dark: "",
                                                                  fab: ""
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.splitActionsFab,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.splitActionsFab = $$v
                                                                  },
                                                                  expression:
                                                                    "splitActionsFab"
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _vm.splitActionsFab
                                                                ? _c("v-icon", [
                                                                    _vm._v(
                                                                      "\n                        mdi-close\n                      "
                                                                    )
                                                                  ])
                                                                : _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "text-h5"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                        " +
                                                                          _vm._s(
                                                                            _vm.$_splitMixin_indexToChar(
                                                                              _vm.scrollSplitIndex
                                                                            )
                                                                          ) +
                                                                          "\n                      "
                                                                      )
                                                                    ]
                                                                  )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  51745934
                                                )
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Split " +
                                                      _vm._s(
                                                        _vm.$_splitMixin_indexToChar(
                                                          _vm.scrollSplitIndex
                                                        )
                                                      ) +
                                                      " Actions"
                                                  )
                                                ])
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    989763188
                                  ),
                                  model: {
                                    value: _vm.splitActionsFab,
                                    callback: function($$v) {
                                      _vm.splitActionsFab = $$v
                                    },
                                    expression: "splitActionsFab"
                                  }
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        disabled: _vm.isLoading(),
                                                        fab: "",
                                                        small: "",
                                                        color: "success"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.sendSplitTest(
                                                            _vm.scrollSplitIndex
                                                          )
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("mdi-send")
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2596374741
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "Test Split " +
                                            _vm._s(
                                              _vm.$_splitMixin_indexToChar(
                                                _vm.scrollSplitIndex
                                              )
                                            )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-expansion-panels",
                        {
                          attrs: { multiple: "" },
                          model: {
                            value: _vm.splitMixin_expanded,
                            callback: function($$v) {
                              _vm.splitMixin_expanded = $$v
                            },
                            expression: "splitMixin_expanded"
                          }
                        },
                        _vm._l(_vm.splitMixin_splits, function(item, i) {
                          return _c(
                            "v-expansion-panel",
                            { key: "test-panel-" + i },
                            [
                              _c(
                                "v-expansion-panel-header",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "actions",
                                        fn: function() {
                                          return [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color:
                                                    _vm
                                                      .$_splitMixin_splitColors[
                                                      i
                                                    ]
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    $expand\n                  "
                                                )
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _vm.$_splitMixin_isSplit
                                        ? _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "4",
                                                sm: "3",
                                                md: "2"
                                              }
                                            },
                                            [
                                              _c("v-progress-linear", {
                                                attrs: {
                                                  striped: "",
                                                  height: "20",
                                                  value: item.distribution,
                                                  color:
                                                    _vm
                                                      .$_splitMixin_splitColors[
                                                      i
                                                    ]
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var value = ref.value
                                                        return [
                                                          _c("strong", [
                                                            _vm._v(
                                                              _vm._s(value) +
                                                                "%"
                                                            )
                                                          ])
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "8",
                                            sm: "9",
                                            md: "10"
                                          }
                                        },
                                        [
                                          _vm.$_splitMixin_isSplit
                                            ? _c(
                                                "div",
                                                { staticClass: "inline-block" },
                                                [
                                                  _vm._v(
                                                    "\n                      Split " +
                                                      _vm._s(
                                                        _vm.$_splitMixin_indexToChar(
                                                          i
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                { staticClass: "inline-block" },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(item.subject) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                          item.spam
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "div",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "inline-block"
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                !item.spam
                                                                  .loading
                                                                  ? _c(
                                                                      "v-rating",
                                                                      {
                                                                        staticClass:
                                                                          "inline-block pl-6",
                                                                        attrs: {
                                                                          length:
                                                                            item
                                                                              .spam
                                                                              .maxScore,
                                                                          "empty-icon":
                                                                            "mdi-shield-outline",
                                                                          "full-icon":
                                                                            "mdi-shield-alert",
                                                                          "half-icon":
                                                                            "mdi-shield-half-full",
                                                                          "half-increments":
                                                                            "",
                                                                          readonly:
                                                                            "",
                                                                          dense:
                                                                            "",
                                                                          color: _vm.spamRatingColor(
                                                                            item
                                                                          ),
                                                                          "background-color": _vm.spamRatingBackgroundColor(
                                                                            item
                                                                          ),
                                                                          size:
                                                                            "20"
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            item
                                                                              .spam
                                                                              .scoreRounded,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              item.spam,
                                                                              "scoreRounded",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "item.spam.scoreRounded"
                                                                        }
                                                                      }
                                                                    )
                                                                  : _c(
                                                                      "v-skeleton-loader",
                                                                      {
                                                                        attrs: {
                                                                          type:
                                                                            "heading"
                                                                        }
                                                                      }
                                                                    )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  !item.spam.loading
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "Spam Score: " +
                                                            _vm._s(
                                                              item.spam.score
                                                            ) +
                                                            "/" +
                                                            _vm._s(
                                                              item.spam.maxScore
                                                            )
                                                        )
                                                      ])
                                                    : _c("span", [
                                                        _vm._v("Loading")
                                                      ])
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            { staticClass: "inline-block" },
                                            [
                                              !item.links || item.links.loading
                                                ? _c("v-skeleton-loader", {
                                                    attrs: { type: "heading" }
                                                  })
                                                : item.links.items.length
                                                ? _c(
                                                    "div",
                                                    { staticClass: "pl-6" },
                                                    [
                                                      !item.links.loading &&
                                                      item.links.totalInvalid >
                                                        0
                                                        ? _c("span", [
                                                            _vm._v(
                                                              "Links: " +
                                                                _vm._s(
                                                                  item.links
                                                                    .totalInvalid
                                                                ) +
                                                                "/" +
                                                                _vm._s(
                                                                  item.links
                                                                    .items
                                                                    .length
                                                                ) +
                                                                " Invalid"
                                                            )
                                                          ])
                                                        : !item.links.loading &&
                                                          item.links
                                                            .totalInvalid === 0
                                                        ? _c("span", [
                                                            _vm._v(
                                                              "Links: All " +
                                                                _vm._s(
                                                                  item.links
                                                                    .items
                                                                    .length
                                                                ) +
                                                                " Valid"
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "pl-6",
                                                              attrs: {
                                                                color: _vm
                                                                  .splitsAreTested[
                                                                  i
                                                                ]
                                                                  ? "success"
                                                                  : "grey"
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm
                                                                    .splitsAreTested[
                                                                    i
                                                                  ]
                                                                    ? "mdi-send-check"
                                                                    : "mdi-send"
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _vm.splitsAreTested[i]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "Tested on " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "displayDateTimeFormat"
                                                          )(
                                                            _vm.$_global_parseAsUtc(
                                                              _vm
                                                                .splitsTestedOn[
                                                                i
                                                              ]
                                                            )
                                                          )
                                                        )
                                                    )
                                                  ])
                                                : _c("span", [
                                                    _vm._v("Not tested")
                                                  ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-expansion-panel-content",
                                {
                                  directives: [
                                    {
                                      name: "intersect",
                                      rawName: "v-intersect.quiet",
                                      value: {
                                        handler: _vm.onIntersect,
                                        options: {
                                          threshold: [
                                            0.0,
                                            0.05,
                                            0.1,
                                            0.15,
                                            0.2,
                                            0.25,
                                            0.3,
                                            0.35,
                                            0.4,
                                            0.45,
                                            0.5,
                                            0.55,
                                            0.6,
                                            0.65,
                                            0.7,
                                            0.75,
                                            0.8,
                                            0.85,
                                            0.9,
                                            0.95,
                                            1.0
                                          ]
                                        }
                                      },
                                      expression:
                                        "{\n                  handler: onIntersect,\n                  options: {\n                    threshold: [0.0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1.0]\n                  }\n                }",
                                      modifiers: { quiet: true }
                                    }
                                  ],
                                  class: _vm.$_splitMixin_indexToChar(i)
                                },
                                [
                                  _c("v-divider"),
                                  _c(
                                    "v-row",
                                    { staticClass: "ma-0" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          style:
                                            "height: " +
                                            _vm.containerHeight[i] +
                                            "px; overflow: auto;",
                                          attrs: { cols: "12", md: "6" }
                                        },
                                        [
                                          _c("SplitPreview", {
                                            ref: "splitPreview",
                                            refInFor: true,
                                            attrs: { id: item.id },
                                            on: {
                                              change: function(value) {
                                                return _vm.changedDevice(
                                                  value,
                                                  item,
                                                  i
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          style:
                                            "height: " +
                                            _vm.containerHeight[i] +
                                            "px; overflow: auto;",
                                          attrs: { cols: "12", md: "6" }
                                        },
                                        [
                                          _c(
                                            "v-expansion-panels",
                                            {
                                              attrs: {
                                                multiple: "",
                                                value: [0, 1]
                                              }
                                            },
                                            [
                                              _c(
                                                "v-expansion-panel",
                                                [
                                                  _c(
                                                    "v-expansion-panel-header",
                                                    {
                                                      staticClass: "text-h6",
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "actions",
                                                            fn: function() {
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        _vm
                                                                          .$_splitMixin_splitColors[
                                                                          i
                                                                        ]
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                              $expand\n                            "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            },
                                                            proxy: true
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          Spam Score\n                          "
                                                      ),
                                                      item.spam
                                                        ? _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                top: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "div",
                                                                          _vm._g(
                                                                            {
                                                                              staticClass:
                                                                                "inline-block"
                                                                            },
                                                                            on
                                                                          ),
                                                                          [
                                                                            !item
                                                                              .spam
                                                                              .loading
                                                                              ? _c(
                                                                                  "v-rating",
                                                                                  {
                                                                                    staticClass:
                                                                                      "inline-block pl-6",
                                                                                    attrs: {
                                                                                      length:
                                                                                        item
                                                                                          .spam
                                                                                          .maxScore,
                                                                                      "empty-icon":
                                                                                        "mdi-shield-outline",
                                                                                      "full-icon":
                                                                                        "mdi-shield-alert",
                                                                                      "half-icon":
                                                                                        "mdi-shield-half-full",
                                                                                      "half-increments":
                                                                                        "",
                                                                                      readonly:
                                                                                        "",
                                                                                      dense:
                                                                                        "",
                                                                                      color: _vm.spamRatingColor(
                                                                                        item
                                                                                      ),
                                                                                      "background-color": _vm.spamRatingBackgroundColor(
                                                                                        item
                                                                                      ),
                                                                                      size:
                                                                                        "20"
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        item
                                                                                          .spam
                                                                                          .scoreRounded,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          item.spam,
                                                                                          "scoreRounded",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "item.spam.scoreRounded"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              : _c(
                                                                                  "v-skeleton-loader",
                                                                                  {
                                                                                    attrs: {
                                                                                      type:
                                                                                        "heading"
                                                                                    }
                                                                                  }
                                                                                )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            },
                                                            [
                                                              !item.spam.loading
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "Spam Score: " +
                                                                        _vm._s(
                                                                          item
                                                                            .spam
                                                                            .score
                                                                        ) +
                                                                        "/" +
                                                                        _vm._s(
                                                                          item
                                                                            .spam
                                                                            .maxScore
                                                                        )
                                                                    )
                                                                  ])
                                                                : _c("span", [
                                                                    _vm._v(
                                                                      "Loading"
                                                                    )
                                                                  ])
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-expansion-panel-content",
                                                    [
                                                      _c("SpamScore", {
                                                        attrs: {
                                                          spam: item.spam
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-expansion-panel",
                                                [
                                                  _c(
                                                    "v-expansion-panel-header",
                                                    {
                                                      staticClass: "text-h6",
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "actions",
                                                            fn: function() {
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        _vm
                                                                          .$_splitMixin_splitColors[
                                                                          i
                                                                        ]
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                              $expand\n                            "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            },
                                                            proxy: true
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          Link Validation\n                          "
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "inline-block"
                                                        },
                                                        [
                                                          !item.links ||
                                                          item.links.loading
                                                            ? _c(
                                                                "v-skeleton-loader",
                                                                {
                                                                  attrs: {
                                                                    type:
                                                                      "heading"
                                                                  }
                                                                }
                                                              )
                                                            : item.links.items
                                                                .length
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pl-6 text-subtitle-1"
                                                                },
                                                                [
                                                                  !item.links
                                                                    .loading &&
                                                                  item.links
                                                                    .totalInvalid >
                                                                    0
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item
                                                                                .links
                                                                                .totalInvalid
                                                                            ) +
                                                                              "/" +
                                                                              _vm._s(
                                                                                item
                                                                                  .links
                                                                                  .items
                                                                                  .length
                                                                              ) +
                                                                              " Invalid"
                                                                          )
                                                                        ]
                                                                      )
                                                                    : !item
                                                                        .links
                                                                        .loading &&
                                                                      item.links
                                                                        .totalInvalid ===
                                                                        0
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Links: All " +
                                                                              _vm._s(
                                                                                item
                                                                                  .links
                                                                                  .items
                                                                                  .length
                                                                              ) +
                                                                              " Valid"
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-expansion-panel-content",
                                                    [
                                                      _c("LinkChecker", {
                                                        attrs: {
                                                          "split-id": item.id,
                                                          links: item.links
                                                        },
                                                        on: {
                                                          "set-link-validation": function(
                                                            value
                                                          ) {
                                                            return _vm.$_splitMixin_setLinks(
                                                              i,
                                                              value
                                                            )
                                                          },
                                                          "load-split-html": function(
                                                            $event
                                                          ) {
                                                            return _vm.loadSplit(
                                                              i
                                                            )
                                                          }
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "success", loading: _vm.isLoading() },
                  on: {
                    click: function($event) {
                      return _vm.testAll()
                    }
                  }
                },
                [
                  _vm._v("\n        Test All\n        "),
                  _c("v-icon", { attrs: { right: "" } }, [
                    _vm._v("\n          mdi-send-circle\n        ")
                  ])
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "tertiary", disabled: _vm.isLoading() },
                  on: {
                    click: function($event) {
                      return _vm.cancel()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("\n          mdi-menu-left-outline\n        ")
                  ]),
                  _vm._v("Back\n      ")
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "success", loading: _vm.isLoading() },
                  on: {
                    click: function($event) {
                      return _vm.submitContent()
                    }
                  }
                },
                [
                  _vm._v("\n        Next\n        "),
                  _c("v-icon", { attrs: { right: "" } }, [
                    _vm._v("\n          mdi-menu-right-outline\n        ")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }